/** @jsx jsx */
import { jsx, Styled, Flex } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from "rebass"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Kiitos yhteydenotosta" />
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bg: "blueLight",
      }}
    >
      <Styled.h1>Kiitos yhteydenotosta!</Styled.h1>
      <Button variant="primary" as={Link} to="/">
        Takaisin etusivulle &rarr;
      </Button>
    </Flex>
  </Layout>
)

export default NotFoundPage
